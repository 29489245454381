/*
===============================================================================
 Lists
===============================================================================
*/
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
===============================================================================
 Home Animation
===============================================================================
*/
#page-top-home {
  background: $white url("/assets/images/keyframe-home/animation-bg-space.jpg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  overflow: hidden;

  > strong {
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 30px;
    left: 0%;
  }

  @media screen and (min-width: $tablet-landscape) {
    height: 600px;
  }
}

#kf-home-1 {
  position: absolute;
  left: 0;
  bottom: 10%;
  z-index: 1;
  opacity: .5;
  width: 120px;

  animation: homeclip 20s infinite;
  -webkit-animation: homeclip 20s infinite;

  @media screen and (min-width: $tablet-landscape) {
    width: 400px;
  }
}

@keyframes homeclip {
  0% {
    opacity: .2;
    left: -20%;
    bottom: 10%;
    width: 600px;
  }

  50% {
    opacity: 1;
    left: 45%;
    bottom: 40%;
    width: 200px;
  }
  100% {
    opacity: .2;
    left: 100%;
    bottom: 10%;
    width: 600px;
  }
}


/*
===============================================================================
 Fading animation
===============================================================================
*/
#slider-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;

  > div.slide-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: $tablet-landscape) {
    height: 600px;
  }
}

#slide-1 {
  animation: fade 9s infinite;
  -webkit-animation: fade 9s infinite;
}

#slide-2 {
  animation: fade2 9s infinite;
  -webkit-animation: fade2 9s infinite;
}

#slide-3 {
  animation: fade3 9s infinite;
  -webkit-animation: fade3 9s infinite;
}

#slide-4 {
  animation: fade4 9s infinite;
  -webkit-animation: fade4 9s infinite;
}

#slide-5 {
  animation: fade5 9s infinite;
  -webkit-animation: fade5 9s infinite;
}

#slide-6 {
  animation: fade6 9s infinite;
  -webkit-animation: fade6 9s infinite;
}

@keyframes fade {
  0% {
    opacity: 1
  }
  16% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes fade2 {
  0% {
    opacity: 0
  }
  16% {
    opacity: 1
  }
  33% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes fade3 {
  0% {
    opacity: 0
  }
  16% {
    opacity: 0
  }
  33% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes fade4 {
  0% {
    opacity: 0
  }
  16% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes fade5 {
  0% {
    opacity: 0
  }
  16% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  66% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes fade6 {
  0% {
    opacity: 0
  }
  16% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*
===============================================================================
 References animation
===============================================================================
*/

#slide-ref-1 {
  animation: refclip 9s infinite;
  -webkit-animation: refclip 9s infinite;
}

#slide-ref-2 {
  animation: refclip2 9s infinite;
  -webkit-animation: refclip2 9s infinite;
}

#slide-ref-3 {
  animation: refclip3 9s infinite;
  -webkit-animation: refclip3 9s infinite;
}

#slide-ref-4 {
  animation: refclip4 9s infinite;
  -webkit-animation: refclip4 9s infinite;
}

@keyframes refclip {
  0% {
    opacity: 1
  }
  33% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes refclip2 {
  0% {
    opacity: 0
  }
  33% {
    opacity: 1
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes refclip3 {
  0% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  66% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes refclip4 {
  0% {
    opacity: 0
  }
  33% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*
===============================================================================
 Technology animation
===============================================================================
*/

#slide-tec-1 {
  animation: tecclip 4s infinite;
  -webkit-animation: tecclip 4s infinite;
}

#slide-tec-2 {
  animation: tecclip2 4s infinite;
  -webkit-animation: tecclip2 4s infinite;
}

@keyframes tecclip {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes tecclip2 {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*
===============================================================================
 Team animation
===============================================================================
*/
#kf-team-1,#kf-team-2,#kf-team-3 {
  opacity: .2;

  > div.bg-blue {
    position: absolute;
    bottom: 40px;
    left: 0;
    z-index: 1;
    opacity: 0;
  }
}

#kf-team-1 {
  animation: teamclip1 7s infinite;
  -webkit-animation: teamclip1 7s infinite;
}

#kf-team-2 {
  animation: teamclip2 7s infinite;
  -webkit-animation: teamclip2 7s infinite;
}

#kf-team-3 {
  animation: teamclip3 7s infinite;
  -webkit-animation: teamclip3 7s infinite;
}

#kf-team-4 {
  animation: teamclip4 7s infinite;
  -webkit-animation: teamclip4 7s infinite;
}

#kf-team-5 {
  animation: teamclip5 7s infinite;
  -webkit-animation: teamclip5 7s infinite;
}

#kf-team-6 {
  animation: teamclip6 7s infinite;
  -webkit-animation: teamclip6 7s infinite;
}

@keyframes teamclip1 {
  0% {
    opacity: 1
  }
  50% {
    opacity: .2
  }
  100% {
    opacity: .2
  }
}

@keyframes teamclip2 {
  0% {
    opacity: .2
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: .2
  }
}

@keyframes teamclip3 {
  0% {
    opacity: .2
  }
  50% {
    opacity: .2
  }
  100% {
    opacity: 1
  }
}

@keyframes teamclip4 {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes teamclip5 {
  0% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes teamclip6 {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}