@import "_fonts";
@import "_constants";
@import "_grid";
@import "_elements";
@import "_components";
@import "_navigations";
/*
===============================================================================
 Core
===============================================================================
*/
html {
}

body,
html {
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-family: $font-family-base;
  font-weight: 300;
  background-color: $blue;
  color: $white;
}

a {
  color: $white;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

strong {
  font-weight: 700;
}

main {
  h1 {
    margin-top: $gap-lg;
    margin-bottom: $gap-lg;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 300;

    > small {
      font-size: 80%;
      font-weight: 700;
      display: block;
    }
  }

  h2, h3, h4 {
    margin-top: $gap-lg;
    font-weight: 300;
  }

  p, ul, ol, dl, h2, h3, h4 {
    margin-bottom: $gap-base;
  }

  ul, ol {
    padding-left: $gap-base;
    line-height: 1.6rem;
  }
}

/*
===============================================================================
 Page header
===============================================================================
*/
#page-header {
  padding-top: $gap-lg;
  padding-bottom: $gap-base;
}

#logo-word-mark {
  width: 227px;
  height: 52px;

  > img {
    width: inherit;
    height: inherit;
  }
}

#logo-figurative-mark {
  width: 95px;
  height: 37px;

  > img {
    width: inherit;
    height: inherit;
  }
}

@media screen and (min-width: $phablet) {
  #logo-figurative-mark {
    width: 150px;
    height: 58px;
  }
}

@media screen and (min-width: $tablet-landscape) {
  #logo-word-mark {
    width: 349px;
    height: 80px;
  }

  #logo-figurative-mark {
    width: 207px;
    height: 80px;
  }
}

/*
===============================================================================
 Page top
===============================================================================
*/
#page-top {
  background-color: $white;
}

/*
===============================================================================
 Page footer
===============================================================================
*/
#page-footer {
  a {
    color: $white;
    display: inline-block;
    margin-left: $gap-lg;
  }
}

/*
===============================================================================
 custom styles
===============================================================================
*/
/* @see technologien.html */
.custom-2 {

  @media screen and (min-width: $tablet-landscape) {
    position: absolute;
    left: 10%;
    top: 60px;
    text-align: center;

    span {
      display: block;
    }

    span:first-child {
      font-size: 5rem;
      line-height: 5rem;
      font-weight: 700;
    }

    span:last-child {
      font-size: 3rem;
      line-height: 4rem;
      font-weight: 300;
    }
  }
}

/* @see kontakt.html */
.map-copyright {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba($white, .75);
  color: black;
  font-size: .8rem;
  padding: .1rem .25rem;
}