/*
===============================================================================
 Colors
===============================================================================
*/
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$blue: rgb(34, 80, 169);
$gray: rgb(95, 98, 113);
$gray-lighter: rgb(189, 190, 204);
$orange: rgb(243, 92, 25);
$blue-silver: rgb(159, 192, 255);
$blue-silver-2: rgb(173, 206, 252);

$shadow: rgba($black, .6);

/*
===============================================================================
 Breakpoints
===============================================================================
*/
$phablet: 576px; // sm
$tablet-portrait: 768px; // md
$tablet-landscape: 992px; // lg
$desktop: 1280px; // xl
$wide-screen: 1600px; // xxl

/*
===============================================================================
 Typography
===============================================================================
*/
$font-family-base: 'Open Sans Condensed', sans-serif;
$font-size-base: 20px;
$line-height-base: 1.4rem;
$font-size-small: .85rem;

/*
===============================================================================
 Margin and padding
===============================================================================
*/
$gap-base: 1rem;
$gap-xxl: 8rem;
$gap-xl: 4rem;
$gap-lg: 2rem;
$gap-sm: .5rem;
$gap-xs: .25rem;

/*
===============================================================================
 Mixins
===============================================================================
*/

/*
===============================================================================
 Default setting
===============================================================================
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
