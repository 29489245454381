/* _sass/grid.scss */

@mixin flex-row() {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: normal;
  justify-content: flex-start;
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  padding-left: $gap-base;
  padding-right: $gap-base;
}

/*
===============================================================================
 Grid system
===============================================================================
*/
.row {
  @include flex-row;
  margin: ($gap-base * -1) 0 0 ($gap-base * -1);
}

.row-direction-row-reverse {
  flex-direction: row-reverse;
}

.col {
  padding: $gap-base 0 0 $gap-base;
  margin: 0;
  min-width: 0;
  max-width: 100%;
}

.row-gapless {
  margin: 0;
  > .col {
    padding: 0;
  }
}

.xs-2 {
  width: 16.66666667%;
}

.xs-4 {
  width: 33.333333333%;
}

.xs-5 {
  width: 41.666666666%;
}

.xs-6 {
  width: 50%;
}

.xs-7 {
  width: 58.333333333%;
}

.xs-8 {
  width: 75%;
}

.xs-10 {
  width: 83.33333333%;
}

.xs-12 {
  width: 100%;
}

@media screen and (min-width: $phablet) {
  .sm-6 {
    width: 50%;
  }
}

@media screen and (min-width: $tablet-portrait) {
  .md-20 {
    width: 20%;
  }

  .md-3 {
    width: 33.333333333%;
  }

  .md-4 {
    width: 33.333333333%;
  }

  .md-5 {
    width: 41.666666666%;
  }

  .md-6 {
    width: 50%;
  }

  .md-7 {
    width: 58.333333333%;
  }

  .md-8 {
    width: 66.666666666%;
  }

  .md-12 {
    width: 100%;
  }
}

@media screen and (min-width: $tablet-landscape) {
  .lg-1 {
    width: 8.333333333%;
  }

  .lg-2 {
    width: 16.666666666%;
  }

  .lg-20 {
    width: 20%;
  }

  .lg-3 {
    width: 25%;
  }

  .lg-4 {
    width: 33.333333333%;
  }

  .lg-5 {
    width: 41.666666666%;
  }

  .lg-6 {
    width: 50%;
  }

  .lg-7 {
    width: 58.333333333%;
  }

  .lg-8 {
    width: 66.666666666%;
  }

  .lg-9 {
    width: 75%;
  }

  .lg-10 {
    width: 83.3333333333%;
  }

  .lg-11 {
    width: 91.666666666%;
  }

  .lg-12 {
    width: 100%;
  }

  .offset-lg-1 {
    margin-left: 8.333333333%;
  }

  .offset-lg-4 {
    margin-left: 33.333333333%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .gapless-lg {
    margin: 0;
    > .col {
      padding: 0;
    }
  }
}