/*
===============================================================================
 Images
===============================================================================
*/
.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-responsive-full {
  @extend .img-responsive;
  max-width: initial;
  width: 100%;
}

.img-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-contain {
  @extend .img-cover;
  object-fit: contain;
}

/*
===============================================================================
 Typo
===============================================================================
*/
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-right-lg {
  @media screen and (min-width: $tablet-landscape) {
    text-align: right;
  }
}

.uppercase {
  text-transform: uppercase;
}

.text-strong {
  font-weight: 700;
}

.text-light {
  font-weight: 300;
}

.headline-2 {
  margin-top: $gap-lg;
  margin-bottom: $gap-xl;
  text-align: right;
}

.well {
  @media screen and (min-width: $tablet-landscape) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

/*
===============================================================================
 Color (Backgrounds)
===============================================================================
*/
.white {
  color: $white;
}

.black {
  color: $black;
}

.gray-lighter {
  color: $gray-lighter;
}

.bg-white {
  background-color: $white;
}

.bg-blue {
  background-color: $blue;
}

.bg-gray-lighter {
  background-color: $gray-lighter;
}

.bg-gray {
  background-color: $gray;
}

.bg-gray-white {
  background: linear-gradient(90deg, $gray-lighter, $white);
}

.bg-gray-white-gray {
  background: linear-gradient(90deg, $gray-lighter, $white, $gray-lighter);
}

/*
===============================================================================
 Paddings, Margins
===============================================================================
*/
.py-1 {
  padding-top: $gap-xs;
  padding-bottom: $gap-xs;
}

.p-3 {
  padding: $gap-base;
}

.pt-3 {
  padding-top: $gap-base;
}

.pb-3 {
  padding-bottom: $gap-base;
}

.pt-4 {
  padding-top: $gap-lg;
}

.pb-4 {
  padding-bottom: $gap-lg;
}

.py-5 {
  padding-top: $gap-xl;
  padding-bottom: $gap-xl;
}

.pb-lg-5 {
  @media screen and (min-width: $tablet-landscape) {
    padding-bottom: $gap-xl;
  }
}

.pt-custom-1 {
  @media screen and (min-width: $tablet-landscape) {
    padding-top: 75px;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mt-4 {
  margin-top: $gap-lg;
}

.mb-3 {
  margin-bottom: $gap-base;
}

/*
===============================================================================
 Display
===============================================================================
*/
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

/*
===============================================================================
 Position
===============================================================================
*/
.relative {
  position: relative;
}

/*
===============================================================================
 Widths and Heights
===============================================================================
*/
.h-100 {
  height: 100%;
}

/*
===============================================================================
 Buttons
===============================================================================
*/
.btn {
  padding: $gap-sm $gap-base;
  background-color: transparent;
  display: inline-block;
}

.btn-primary {
  background-color: $orange;
  color: $white;
  font-weight: 700;
}