/*
-------------------------------------------------------------------------------
 Main navigation
-------------------------------------------------------------------------------
*/
#main-nav {
  position: relative;
  z-index: 2;
}

#main-nav[data-status="open"] {

}

#main-nav-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 40px;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  z-index: 3;

  &:active,
  &:focus,
  &:hover {
    outline: none !important;
  }

  @media screen and (min-width: $tablet-landscape) {
    top: -15px;
  }
}

.bar {
  position: absolute;
  left: 0;
  height: 4px;
  width: 50px;
  background: $blue-silver-2;
  border-radius: 2px;
  display: block;
  transition: 0.5s;
  transform-origin: center;
}

.bar:nth-child(1) {
  top: 12px;
}

.bar:nth-child(2) {
  top: 24px;
}

.bar:nth-child(3) {
  top: 36px;
}

#main-nav[data-status="open"] > button .bar:nth-child(1) {
  transform: translateY(12px) rotate(-45deg);
}

#main-nav[data-status="open"] > button .bar:nth-child(2) {
  opacity: 0;
}

#main-nav[data-status="open"] > button .bar:nth-child(3) {
  transform: translateY(-12px) rotate(45deg);
}

#main-nav-list {
  position: absolute;
  right: $gap-base * -1;
  top: 65px;
  z-index: 2;
  display: none;
  @extend .nav-list;
  background-color: $blue;
  padding: $gap-lg;

  a {
    display: block;
    line-height: 1.8rem;

    &:hover, &:focus {
      text-decoration: none;
      color: $white;
    }
  }

  @media screen and (min-width: $tablet-landscape) {
    right: 0;
    top: 48px;
  }
}
